import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectBanner } from "../components/main-banner/ProjectBanner";

import { Editor } from '@tinymce/tinymce-react';


import ProjectList from './../lists/Projects.json'
import { TextEditor } from "../components/wysiwyg/TextEditor";
import { PencilIcon } from "@heroicons/react/24/solid";
import { Project } from "../components/projects/Project";
import { ProjectType } from "../lists/types";
import { addProject, fetchProjects } from "../API/api";
import { ButtonSmall } from "../components/buttons/ButtonSmall";

export const Overview = () => {
  const [item, setItem] = React.useState({
    "title": "Alle voorgaande projecten",
    "body": "Hier vindt u een overzicht van alle voorgaande projecten. Klik op een project voor meer informatie."
  })
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false)
  const toggleEdit = () => {setEdit(!edit)}
  
  const [content, setContent] = useState('<p>Schrijf een nieuwe blog...</p>'); 

  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = React.useState(false)

  useEffect(() => {
    if(localStorage.getItem('stko') === 'true'){
      setIsAdmin(true)
    }
  }, [])

  const emptyProject = {
    title: 'Nieuw Project',
    body: 'Beschrijving van het nieuwe project',
    wysiwyg: 'Inhoud van het nieuwe project',
    image: '',
    id: 0,
    archived: false
  }
  
  useEffect(() => {
    const getProjects = async () => {
      try {
        const data = await fetchProjects();
        setProjects(data);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      } finally {
        setLoading(false);
      }
    };

    getProjects();
  }, []);

  const navigate = (link: string) => {
    window.location.href = link
  }

  const addNewProject = async() => {
    console.log('add new project')
    try{
      let newItem = await addProject(emptyProject)
      console.log('newItem', newItem)
      navigate(`/project/${newItem._id}`)
    } catch {
      console.error('Failed to add new project')
    }
  }

  return (
    <div className="font-gordita">
      <ProjectBanner item={item}/> 
      <div className="container mx-auto max-w-6xl px-4 relative">
        <div className="py-4 ">
          {projects.map((project, index) => (
            <>
              <Project data={project} key={index}/>
              <hr className="mt-8"/>
            </>
          ))}
        </div>
        {isAdmin &&
          <div>
            <div className="m-auto lg:ml-auto w-fit mt-4">
              <button className="button-small" onClick={() => addNewProject()}>
                {'Nieuw Project Toevoegen'}
              </button>          
            </div>
          </div>
        }
      </div> 
    </div>
  );
}   