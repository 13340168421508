import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ParticipantColor } from "./ParticipantColor";
import { SupportTeamType } from "../../lists/types";
import { fetchSupportTeam } from "../../API/api";

export const SupportedBy = () => {
  const [supportTeam, setSupportTeam] = useState<SupportTeamType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const emptyParticipant = {
    name: '',
    position: '',
    email: ''
  }

  React.useEffect(()=>{
    if(localStorage.getItem('isAdmin') === 'true'){
      setIsAdmin(true)
    }
  }, [])

  const getSupportTeam = async () => {
    try {
      const data = await fetchSupportTeam();
      setSupportTeam(data);
    } catch (error) {
      console.error('Failed to fetch supportTeam:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    getSupportTeam();
  }, []);

  const { t } = useTranslation();
  return(
    <div className="content-block bg-white">
      <div className="max-w-6xl m-auto w-full text-center lg:text-left">
        <div className="header-text">
          {t("whoIsSupported")}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {supportTeam.map((participant, index) => (
            <ParticipantColor data={participant} key={index} isNew={false} />
          ))}
          {isAdmin &&
            <div>
              <ParticipantColor data={emptyParticipant} key={999} isNew={true} refresh={getSupportTeam}/>
            </div>
          }
        </div>
      </div>
      <div className="text-center text-2xl mt-3" dangerouslySetInnerHTML={{__html: t('whoIsFooter')}}>
      </div>
    </div>
  )
}