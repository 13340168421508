import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ProjectImg from "../../images/project2.png";
import { Participant } from "./Participant";
import { fetchMainTeam } from "../../API/api";
import { MainTeamType } from "../../lists/types";

export const MainTeam = () => {
  const { t } = useTranslation();
  const [mainTeam, setMainTeam] = useState<MainTeamType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const emptyParticipant = {
    name: '',
    position: '',
    email: ''
  }

  React.useEffect(()=>{
    if(localStorage.getItem('isAdmin') === 'true'){
      setIsAdmin(true)
    }
  }, [])

  const getMainTeam = async () => {
    try {
      const data = await fetchMainTeam();
      setMainTeam([...data]);
    } catch (error) {
      console.error('Failed to fetch mainTeam:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMainTeam();
  }, []);
  
  return(
    <div className="content-block text-white bg-main-color" id="link-team">
      <div className="max-w-6xl m-auto w-full px-5 text-center lg:text-left">
        <div className="flex flex-col lg:flex-row lg:gap-x-8">
          <div className="flex flex-col place-content-center">
            <div className="header-text-white">
              {t("whoIsHeader")}
            </div>
            <div className="mt-4" dangerouslySetInnerHTML={{__html: t('whoIsText')}}>
            </div>
          </div>
          <img 
            className="mt-8 lg:mt-0"
            src={ProjectImg}
          />
        </div>
        <div className="header-text-white pt-8">
          {t("whoIsBestuur")}
        </div>
        <div className="grid grid-cols-2">
          {mainTeam.map((participant, index) => (
            <Participant data={participant} key={index} isNew={false} refresh={getMainTeam}/>
          ))}
          {isAdmin && 
            <div>
              <Participant data={emptyParticipant} key={999} isNew={true} refresh={getMainTeam}/>
            </div>
          }
        </div>
      </div>
    </div>
  )
}