import React from "react";
import BannerImage from "./../../images/bannerImg.jpg";
import { useTranslation } from "react-i18next";
import { ButtonLarge } from "../buttons/ButtonLarge";
import { ScrollToComponent } from "../../functions/ScrollToComponent";
import { ButtonSmall } from "../buttons/ButtonSmall";

import ProjectImg from './../../images/Project1Img.jpg'

export const ProjectBanner = ({item, setItem, edit = false}: any) => {
  const { t, i18n } = useTranslation();
  
  return(
    <div className="" id="main-banner">
      <div className="relative">
        <img 
          className="absolute inset-0 h-[45vh] object-cover w-full"
          src={item.img || ProjectImg}
          alt="banner image"
        />
        <div className="w-screen h-[45vh] bg-black/70 backdrop-blur-sm absolute inset-0" />
        <div className="relative z-10 h-[45vh] max-w-6xl m-auto">
          <div className="w-full flex flex-col h-full">
            <div className="flex-1"></div>
            <div className="text-white font-bold text-4xl lg:text-4xl text-left lg:pb-8 px-4 ">
              {!edit ? 
                item.title
              : 
                <input 
                  className="text-black rounded-lg px-4 pb-1 pt-3  w-full"
                  placeholder={'Title'}
                  value={item.title}
                  onChange={(e) => setItem({...item, title: e.target.value})}
                />
              }
            </div>
            <div className="text-white text-xl lg:text-2xl text-left px-4 hidden lg:block">
              {!edit ?
                item.body
              :   
                <textarea
                  className="text-black rounded-lg px-4 pb-1 pt-3 w-full"
                  placeholder={'Body'}
                  rows={5}
                  value={item.body}
                  onChange={(e) => setItem({...item, body: e.target.value})}
                />
              }     
            </div>  
            <div className="text-black text-lg lg:text-lg text-left pb-4 px-4 mt-4">
              {edit &&
                <input 
                  className="text-black rounded-lg px-4 pb-1 pt-3 w-full"
                  placeholder={'Afbeelding'}
                  value={item.img}
                  onChange={(e) => setItem({...item, img: e.target.value})}
                />
              }
            </div>
          </div>
        </div>
      </div>
      {/* <div className="absolute inset-0 bg-black/70 backdrop-blur-sm grid place-content-center px-14" >
        <div className="text-white font-bold text-4xl lg:text-7xl text-left pb-12 px-2">
          {item.title} 
        </div>
        <div className="text-white text-2xl lg:text-5xl text-left px-4">
          {item.body}        
        </div>
      </div> */}
    </div>
  )
}