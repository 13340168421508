import React from "react";
import { ButtonSmall } from "../buttons/ButtonSmall";
import { useTranslation } from "react-i18next";
import { CheckIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { addMainTeamMember, removeMainTeamMember, updateMainTeamMember } from "../../API/api";

export const Participant = ({ data, isNew, refresh }: any) => {
  const [fields, setFields] = React.useState(data)
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = React.useState(isNew)
  const toggleEdit = () => {setIsEdit(!isEdit)}
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [confirmDelete, setConfirmDelete] = React.useState(false)

  React.useEffect(()=>{
    if(localStorage.getItem('isAdmin') === 'true'){
      setIsAdmin(true)
    }
  }, [])

  const resetFields = async() => {
    setFields({
      name: '',
      position: '',
      email: ''
    })
    setIsEdit(isNew)
    // await refresh()
    window.location.reload()
  }

  const updateFields = (val: any, field: any) => {
    let temp = fields
    temp[field] = val
    setFields({...temp})
  }

  const updateTeamMember = () => {
    if(!isNew){
      try{
        updateMainTeamMember(fields._id, fields)
        toggleEdit()
        resetFields()

      } catch(error) {
        console.error('Failed to update team member:', error);
      }
    }
    else{
      try{
        addMainTeamMember(fields)
        toggleEdit()
        resetFields()

      } catch(error) {
        console.error('Failed to add team member:', error);
      }
    }
  }

  const handleRemoveMember = (id: any) => {
    try{
      removeMainTeamMember(id)
      resetFields()

    } catch(error) {
      console.error('Failed to remove team member:', error);
    }
  }

  return(
    <div className="flex flex-col lg:flex-row w-full gap-x-12 py-6 text-white">
      <div>
        <img 
          className="w-[100px] aspect-square bg-white overflow-hidden object-cover rounded-lg m-auto lg:m-0 mb-4 lg:mb-0"
          src={data.img}
        />
      </div>
      <div className="flex flex-col place-content-center grid"> 
        {isEdit?
          <div className="flex flex-col gap-y-2">
            <div>
              <span className="mr-3 pr-3">Naam:</span>
              <input 
                className="input-field-small"
                placeholder={'Naam'}
                value={fields.name}
                onChange={(e) => updateFields(e.target.value, 'name')}
              />
            </div>
            <div>
              <span className="mr-3 ">Functie:</span>
              <input 
                className="input-field-small"
                placeholder={'Functie'}
                value={fields.position}
                onChange={(e) => updateFields(e.target.value, 'position')}
              />
            </div>
            <div>
              <span className="mr-3 pr-4">Email:</span>
              <input 
                className="input-field-small"
                placeholder={'Email'}
                value={fields.email}
                onChange={(e) => updateFields(e.target.value, 'email')}
              />
            </div>
          </div>
        :
          <div>
            <div className="font-semibold">
              {fields.name}
            </div> 
            <div>
              {fields.position}
            </div>
            <div className="hover:underline cursor-pointer">
              {fields.email}
            </div>
          </div>
        }
        {isEdit &&
          <div className="flex gap-x-5 mt-4">
            <div className="text-green-300 cursor-pointer" onClick={()=>updateTeamMember()}>
              Opslaan
            </div>
            {!isNew &&
              <>
                <div className="m-auto cursor-pointer" onClick={()=>toggleEdit()}>
                  Annuleren
                </div>
                <div className="ml-auto">
                  {!confirmDelete ?
                    <TrashIcon className="w-4 h-4 text-white hover:text-red-500 cursor-pointer" onClick={()=>setConfirmDelete(true)} />
                    :
                    <CheckIcon className="w-4 h-4 text-white hover:text-green-500 cursor-pointer" onClick={()=>handleRemoveMember(fields._id)} />
                  }
                </div>
              </>
            }
          </div>
        }
      </div>
      {!isNew && isAdmin &&
        <div onClick={()=>toggleEdit()}>
          <PencilIcon className="w-4 h-4 text-white hover:text-white/80 cursor-pointer" />
        </div>
      }
    </div>
  )
}