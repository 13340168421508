import React from "react";
import { useTranslation } from "react-i18next";

export const ButtonLarge = () => {
  const { t, i18n } = useTranslation();

  return(
    <button className="button-large">
      {t('cta')}
    </button>
  )
}