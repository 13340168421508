import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef } from "react";

export const TextEditor = ({content, setContent, toggleEdit}: any) => {
  const editorRef = useRef(null);
  // // const contentFromEditor = (editorRef.current as any)?.getContent();

  // const log = () => {
  //   if (editorRef.current) {
  //     // console.log(JSON.stringify((editorRef.current as any)?.getContent()));
  //     const contentFromEditor = (editorRef.current as any)?.getContent();
  //     setContent(contentFromEditor);
  //   }
  // // };

  // useEffect(()=>{
  //   console.log('content', content)
  //   // const contentFromEditor = (editorRef.current as any)?.getContent();
  //   console.log('contentFromEditor', (editorRef.current as any)?.getContent())
  //   // log()
  // }, [])

  const handleEditorChange = (content: any, editor: any) => {
    console.log('Content was updated:', content);
    // setContent(content)
    let string = content.toString()
    setContent(string)
  }

  return(
    <>
      <Editor
        apiKey='xwsctelxpkkmms0k0fbgln6los7pte43intj8qz7s7uri6gr'
        onInit={(evt, editor) => editorRef.current = editor as any}
        value={content}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' 
            + 'link',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        onEditorChange={handleEditorChange}
      />
      {/* <button className="w-fit h-fit px-2 py-1 rounded-lg border text-base mt-2 hover:bg-green-500 hover:text-white active:bg-green-700 " onClick={() => {log(); toggleEdit()}}>Opslaan</button> */}
    </>
  )
}