import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom"

//pages
import { Home } from "./pages/Home"
import { Navbar } from './components/navbar/Navbar';
import { fetchProjects } from './API/api';
import { ProjectPage } from './pages/ProjectPage';
import { Overview } from './pages/Overview';
import { Abni } from './components/anbi/Anbi';
import { Admin } from './pages/Admin';

function App() {
  

  return (
    <div className="">
      <Navbar /> 
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/project/:id" element={ <ProjectPage /> } />
        <Route path="/overview" element={ <Overview /> } />
        <Route path="/admin" element={ <Admin /> } />
      </Routes>
      <Abni />
    </div>
  );
}

export default App;
