import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MainBanner } from "../components/main-banner/MainBanner";
import { WeAre } from "../components/we-are/WeAre";
import { AboutOurProjects } from "../components/projects/AboutOurProjects";
import { PreviousProjects } from "../components/projects/PreviousProjects";
import { MainTeam } from "../components/WhoIs/MainTeam";
import { SupportedBy } from "../components/WhoIs/SupportedBy";
import { ContactUs } from "../components/Contact/ContactUs";
import { Abni } from "../components/anbi/Anbi";
import { ScrollToComponent } from "../functions/ScrollToComponent";
import { useLocation } from "react-router-dom";

export const Home = () => {
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    // Check if there's a scrollToComponent state
    if (location.state?.scrollToComponent) {
      const component = location.state.scrollToComponent;
      const element = document.getElementById(component);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  //get url and scroll to component
  // useEffect(() => {
  //   const url = window.location.href
  //   if(url){
  //     ScrollToComponent(url)
  //   }
  // }, [])

  return (
    <div className="font-gordita">
      <MainBanner />
      <WeAre />
      <AboutOurProjects />
      <PreviousProjects />
      <MainTeam />
      <SupportedBy />
      <ContactUs />
      {/* <Abni /> */}
    </div>
  );
}   