import React from "react";
import { useTranslation } from "react-i18next";

export const ContactUs = () => {
  const { t } = useTranslation();
  return(
    <div className="content-block text-white bg-main-color" id="link-contact">
      <div className="max-w-6xl m-auto w-full px-5 text-center lg:text-left">
        <div className="flex gap-x-8 max-w-2xl">
          <div className="flex flex-col place-content-center">
            <div className="header-text-white">
              {t("contactUsHeader")}
            </div>
            <div className="mt-4" dangerouslySetInnerHTML={{__html: t('contactUsBody')}}>
            </div>
          </div>
        </div> 
        <div className="flex flex-col mt-4">
          <input 
            className="input-field"
            placeholder={t('name')}
          />
          <input 
            className="input-field"
            placeholder={t('email')}
          />
          <input 
            className="input-field"
            placeholder={t('phone')}
          />
          <textarea
            rows={5} 
            className="input-field"
            placeholder={t('message')}
          />
          <div className="mt-2 w-fit m-auto">
            <button className="button-small-light">
              {t('send')}
            </button>          
          </div>
        </div>
      </div>
    </div>
  )
}